var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"section bg-home",style:({
  'background-image': 'url(' + require('@/assets/images/bg/bg34.jpg') + ')',
  'background-size': 'cover',
  'background-position': 'center',
  'height':'800px'
}),attrs:{"id":"home"}},[_c('div',{staticClass:"bg-overlay"}),_vm._m(0)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"slidero"},[_c('div',{staticClass:"text-white text-center sliderarea"},[_c('div',{staticClass:"col-12 text-white text-center"},[_c('p',{staticClass:"slider_title_top mfont"},[_vm._v("以专业服务为客户创造价值")]),_c('p',{staticClass:"slider_title_center ofont"},[_vm._v(" CREATE VALUE FOR CUSTOMERS WITH SERVICES ")]),_c('p',{staticClass:"slider_title_bottom ofont"},[_vm._v("JIYUAN INFORMATION TECHNOLOGY")])])])])
}]

export { render, staticRenderFns }