import Vue from "vue";
import App from "./App.vue";
import router from "./router"; //导入路由配置
import axios from "./api/axios.js";
axios.defaults.baseURL = "https://admin.jiyuanxinxi.cn/api_web/";
import store from "./store"; // 使用状态管理
import animate from "animate.css"; //引入动画库

//引入项目组件开始
import Scrollspy from "vue2-scrollspy";
import BootstrapVue from "bootstrap-vue";
var VueScrollTo = require("vue-scrollto");
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
Vue.use(VueScrollTo);
Vue.use(BootstrapVue);
Vue.use(Scrollspy);
Vue.use(ElementUI);
Vue.use(animate);
//引入项目组件结束

//引入通用自定义组件与配置开始
import Appconfig from "./config/config.js"; //应用配置参数
import Appfunction from "./assets/js/Functions.js"; //应用自定义函数
Vue.prototype.$axios = axios;
Vue.prototype.sys = Appconfig;
Vue.prototype.grace = Appfunction;

import {
  Message,
  Dialog,
  Loading,
  MessageBox,
  Notification,
  Popover,
} from "element-ui"; //引入组件
Vue.prototype.$Message = Message;
Vue.prototype.$Dialog = Dialog;
Vue.prototype.$Loading = Loading;
Vue.prototype.$MessageBox = MessageBox;
Vue.prototype.$Notification = Notification;
Vue.prototype.$Popover = Popover;
//引入通用自定义组件与配置结束

Vue.config.productionTip = false;
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
